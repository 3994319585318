<template>
  <nav id="navbar">
    <v-app-bar flat app clipped-left>
      <v-toolbar-title class="mt-3">
        <v-btn v-if="larguraApp >= 600" text style="color: #00acc1" plain class="mb-3 font-weight-bold" @click="OnHome">
          <span style="font-size: 25px; margin-right: 10px" class="mdi mdi-home"></span>
          Home
        </v-btn>

        <span v-else @click="OnHome" style="color: #00acc1; font-size: 30px; margin-right: 10px"
          class="mdi mdi-home"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field id="filterAdmin" flat hide-details append-icon="mdi-magnify" placeholder="Pesquisa" outlined dense
        style="margin-left: 40px; margin-right: 20px" v-model="searchText" @click:append="search"
        @keyup.enter="search"></v-text-field>
      <v-spacer></v-spacer>
      <img style="width: 45px" src="../../assets/imgs/logo.png" />
      <v-btn class="btn-plus" :icon="true" height="42px" width="42px"
        style="background-color: #00e676; margin: 0; padding: 0" @click="addingVideo = true"><v-icon
          style="color: white">mdi
          mdi-plus</v-icon></v-btn>
    </v-app-bar>
    <v-dialog v-model="addingVideo">
      <v-col cols="12" sm="12" md="6 offset-md-3" lg="4 offset-lg-4">
        <v-card>
          <v-card-title>
            <span class="text-h5">Adicionar Novo Vídeo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="form-novo-video">
                <v-col cols="12">
                  <v-text-field :rules="[onEmptyField]" id="titulo" density="compact" v-model="videoTitulo"
                    label="Título"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field hint="https://www.youtube.com/watch?v=XXXXXXXXXX" :rules="[verifyUrlRules]" id="url"
                    density="compact" v-model="videoURL" label="URL"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field id="url-img" density="compact" v-model="videoURLImg" label="URL da imagem"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea rows="2" :rules="[onEmptyField]" id="descricao" v-model="videoDescricao" density="compact"
                    label="Descrição"></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions style="display: flex; justify-content: end; align-items: end">
                <v-spacer></v-spacer>
                <v-btn @click="onCancelClick" color="error">Cancelar</v-btn>
                <v-btn color="success" @click="onAddClick">Adicionar</v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-dialog>
  </nav>
</template>

<script>
import { RequestPostNewVideo } from "../../services/ApiRequest";

export default {
  emits: ["SendFilter", "onAlert"],
  data: () => ({
    drawer: false,
    larguraApp: window.innerWidth,
    items: [
      {
        header: null,
        pages: [{ title: "Home", link: "/", icon: "mdi-home" }],
      },
    ],
    searchText: "",
    addingVideo: false,
    videoTitulo: "",
    videoURL: "",
    videoDescricao: "",
    dataAdicao: "",
    videoURLImg: "",
  }),
  components: {},
  created() {
    this.searchText = this.$route.query.filter;
  },
  methods: {
    OnHome() {
      try {
        window.location.reload();
        this.$router.push({ path: "/admin/filter" });
      } catch (error) {
        console.log(error);
      }
    },
    search() {
      this.$emit("SendFilter", this.searchText);
      // this.$router.push({ path: "/admin/home/", query: { filter: this.searchText } });
    },
    onAddClick() {
      if (this.videoTitulo != null && this.videoTitulo != "" && this.videoTitulo != undefined && this.videoURL != null && this.videoURL != "" && this.videoURL != undefined &&
        this.videoDescricao != null && this.videoDescricao != "" && this.videoDescricao != undefined) {

        const newVideo = {
          title: this.videoTitulo,
          url: this.videoURL,
          urlImg: this.videoURLImg,
          description: this.videoDescricao,
          publication_date: new Date(),
        };
        RequestPostNewVideo(newVideo).then(this.afterPostNewVideo);
      }
    },
    afterPostNewVideo() {
      this.addingVideo = false;
      this.clearFields();
      this.$emit("onAlertSucess");
      this.$emit('OnLoadVideos', true);
    },
    onEmptyField(value) {
      if (!value) {
        return "Este campo é obrigatório.";
      }
      return true; // Retorna true se o valor for válido.
    },
    onCancelClick() {
      this.addingVideo = false;
      this.clearFields();
    },
    clearFields() {
      this.videoDescricao = "";
      this.videoTitulo = "";
      this.videoURL = "";
      this.videoURLImg = "";
    },
    verifyUrlRules() {
      if (!this.videoURL.includes("https://www.youtube.com/watch?v=")) {
        return "O campo URL deve ser preenchido no formato: https://www.youtube.com/watch?v=XXXXXXXXXX";
      }
      return true;
    }
  },

  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true;
    this.drawer = this.$route.name === "Watch" ? false : this.drawer;
  },
};
</script>

<style lang="scss">
#navbar {
  .active-item {
    .v-list-item__icon {
      color: #00acc1 !important;
    }
  }

  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
  }

  .vb>.vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb>.vb-dragger>.vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb>.vb-dragger>.vb-dragger-styler {
    width: 10px;
  }

  .vb.vb-scrolling-phantom>.vb-dragger>.vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb>.vb-dragger:hover>.vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging>.vb-dragger>.vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom>.vb-dragger>.vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
}

.btn-plus {
  position: absolute;
  right: -0vw;
  top: 80%;
}

.form-novo-video {
  display: block;
  justify-content: center;
  align-items: center;
}
</style>
