<template>
  <div>
    <Navbar />
    <v-container fluid>
      <v-row>
        <v-col cols="11" class="mx-auto">
          <v-row>
            <v-col cols="12" sm="12" md="8" lg="8">
              <v-responsive>
                <iframe
                  style="width: 100%; height: 70vh"
                  :src="this.UrlYoutube + this.$route.params.id + '?autoplay=1'"
                  frameborder="0"
                  allowfullscreen
                ></iframe>
              </v-responsive>
              <v-card flat class="transparent">
                <v-card-title class="pl-0 pb-0" style="word-break: break-word;">
                  {{ title }}</v-card-title
                >
              </v-card>
              <v-col
                offset="0"
                cols="12"
                md="12"
                style="display: flex; align-items: center;width: 100%;"
              >
              <v-card-text>
                <img
                  style="width: 45px; margin-right: 10px;"
                  src="../assets/imgs/logo.png"
                />
                {{ description }}
              </v-card-text>
                
              </v-col>
            </v-col>

            <v-col cols="12" sm="12" md="4" lg="4">
              <div
                @click="
                  chooseVideo(
                    video.id,
                    video.title,
                    video.description
                  )
                "
                :key="video.id"
                v-for="video in resourceVideos"
                class="thumbnail mb-5"
              >
                <v-skeleton-loader
                  class="mx-auto"
                  type="list-item-avatar-three-line"
                  :loading="loading"
                  tile
                  large
                >
                  <v-hover v-slot:default="{ hover }">
                    <v-card
                      class="card transparent"
                      flat
                      :elevation="hover ? 1 : 0"
                      style="cursor: pointer"
                    >
                      <v-row no-gutters>
                        <v-col class="mx-auto" cols="3" sm="3" md="5" lg="5">
                          <!-- <v-responsive max-height="100%"> -->
                          <v-img
                            class="align-center"
                            :src="video.img"
                          >
                          </v-img>
                          <!-- </v-responsive> -->
                        </v-col>
                        <v-col>
                          <div class="ml-2">
                            <v-card-title
                              class="pl-2 pt-0 subtitle-1 font-weight-bold"
                              style="line-height: 1; word-break: break-word"
                            >
                              {{ video.title }}
                            </v-card-title>

                            <v-card-subtitle
                              class="pl-2 pt-2 pb-0"
                              style="line-height: 1"
                            >
                              {{ video.channelTitle }}<br />
                            </v-card-subtitle>
                          </div>
                        </v-col>
                      </v-row>
                    </v-card>
                  </v-hover>
                </v-skeleton-loader>
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import Navbar from "@/components/Navbar";

import {PostLogGRV} from '../services/ApiRequest';

import { RequestGETCPSVideos } from "../services/ApiRequest";

export default {
  name: "VideoPlayer",
  components: {
    Navbar,
  },
  data() {
    return {
      UrlYoutube: "https://youtube.com/embed/",
      loading: true,
      videoLoading: true,
      UrlVideoPlay: "/videoPlay/",
      resourceVideos: [],
    };
  },
  methods: {
    OnAfterGETVideosCPS(res) {
      let resource = JSON.parse(res.data.itemsAdmin);
      
      for (let i = 0; i < resource.length; i++){
        if ('visible' in resource[i]) {
          if (resource[i].visible == true && resource[i].title != sessionStorage.getItem("title")){
            this.resourceVideos.push(resource[i]);
          }
        }
        else{
          if (resource[i].title != sessionStorage.getItem("title")){
            resource[i].visible = true;
            this.resourceVideos.push(resource[i]);
          }
        }
      }
    },
    chooseVideo(id, title, description) {
      PostLogGRV(
        "1.0.0.0",
        "CPS.EXE",
        "05107093000171",
        "VIDEO_YOUTUBE",
        title
      ).catch((erro) => {
        console.log(erro);
      });

      const inputElement = document.getElementById("filter");
      sessionStorage.setItem("title", title);
      sessionStorage.setItem("description", description);
      this.$router.push({
        path: this.UrlVideoPlay + id,
        query: { filter: inputElement.value },
      });
      window.location.reload();
      window.scrollTo(0, 0);
    },
  },
  computed: {
    title() {
      return sessionStorage.getItem("title").split('&quot;').join('"');
    },
    description() {
      return sessionStorage.getItem("description");
    },
  },

  mounted() {
    setTimeout(() => {
      this.loading = false;
      this.videoLoading = false;
    }, 400);
  },
  
  created() {
    RequestGETCPSVideos()
      .then(this.OnAfterGETVideosCPS)
      .catch((erro) => {
        console.log(erro);
      });

    window.scrollTo(0,0)
  },
};
</script>

<style scoped>
</style>