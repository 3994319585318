import { request, METHOD } from '../library/request'

const channelId = 'UCTX28csW56zJPwr20XnR8Zw'
const Key = 'AIzaSyBhfK1A83gZpo0ssESJm3Tc8Lrk2i_txxk'

const URLLogGRV = 'https://logs.grv.net.br:1880/log';
const URLproducao = 'https://apontamento.grv.net.br:9080/';
//const URLdev = 'http://localhost:9095/';


export async function RequestGETCPSVideos() {
  return request(`${URLproducao}v1/newscps/${channelId}/${Key}`, METHOD.GET, {
  }
  )
}

export async function RequestPostDestaque(body) {
  return request(`${URLproducao}v1/newscps/destaque`, METHOD.POST, body, {
  }
  )
}

export async function PostLogGRV(pVersaoClient, pSistema, pCNPJ, pAcao, pVideo) {
  const DataHoraAtual = new Date();
  const JsonSend = {
    streams: [
      {
        stream: {
          Sistema: pSistema,
          Versao: pVersaoClient,
          Tipo_log: "INFO",
          Criticidade: "BAIXO",
          CNPJ: pCNPJ,
          TipoApp: "WEB",
          video: pVideo,
          acao: pAcao
        },
        values: [
          [
            JSON.stringify(Math.round(DataHoraAtual.getTime() * 1000000)),
            ' '
          ]
        ]
      }
    ]
  }


  return request(URLLogGRV, METHOD.POST, JsonSend, {}, {
    auth: {
      username: 'grv',
      password: 'Log@Grv*H9@Loki'
    }
  })

}

export async function RequestPostVisibleVideo(body) {
  return request(`${URLproducao}v1/newscps/visible`, METHOD.POST, body, {
  }
  )
}


export async function RequestPostNewVideo(body) {
  return request(`${URLproducao}v1/newscps/newvideo`, METHOD.POST, body, {
  }
  )
}

export async function RequestPutVideoAdicionado(body) {
  return request(`${URLproducao}v1/newcps/editvideo`, METHOD.PATCH, body, {
  }
  )
}

export async function getSenhaMaster(password) {
  return request(`${URLproducao}v1/loginsenhamaster/${password}`, METHOD.GET, {
  }
  )
}