<template>
    <div class="login">
        <v-col cols="12" lg="4">
            <v-card elevation="20" style="justify-content: center;">
                <div class="text-center" style="flex: 1; display: flex;justify-content: center;align-items: center;">
                    <v-img src="../../assets//imgs/logo.png" height="120" width="120" contain></v-img>
                </div>
                <v-container>
                    <v-row>
                        <v-col>
                            <v-text-field v-model="password" prepend-icon="mdi mdi-key"
                                :append-icon="show1 ? 'mdi-eye' : 'mdi-eye-off'" :rules="[rules.required, rules.min]"
                                :type="show1 ? 'text' : 'password'" label="Senha da semana"
                                @click:append="show1 = !show1"></v-text-field>
                        </v-col>
                    </v-row>
                </v-container>
                <v-card-actions style="justify-content: center;">
                    <v-btn variant="text" color="#00acc1" rounded width="30%" @click="onLogin">
                        Login
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-col>
    </div>
</template>

<script>
import { getSenhaMaster } from "../../services/ApiRequest"

export default {
    name: "Login",
    data() {
        return {
            show1: false,
            show2: true,
            password: '',
            rules: {
                required: value => !!value || 'Obrigatório.',
            },
        }
    },
    methods: {
        onLogin() {
            getSenhaMaster(this.password).then(this.afterLogin)
                                       .catch(this.onError)

            // if (this.password === this.senhaDaSemana){
            //     this.$emit("onLoginSuccess");
            // } else {
            //     this.$emit("onLogginError");
            // }
        },
        afterLogin() {
            this.$emit("onLoginSuccess");
        },
        onError(res){
            console.log(res)
            this.$emit("onLogginError");
        }
    }
}
</script>

<style scoped>
.login {
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #00acc1;
    height: 100vh;
}
</style>
