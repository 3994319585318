<template>
  <v-app>
    <v-main>
      <router-view/>
    </v-main>
  </v-app>
</template>

<script>

export default {
  name: 'App',

  data: () => ({
    //
  }),
};
</script>

<style>
.v-card__subtitle, .v-card__text, .v-card__title {
     padding: 0;
}
.v-dialog {
    box-shadow: none !important;
}
.v-sheet.v-snack__wrapper:not(.v-sheet--outlined) {
    box-shadow: none !important; 
}
</style>
