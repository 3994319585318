<template>
  <v-card class="content-bg card mx-auto pa-1 transparent" flat @click="OnVideoPlayer">
    <!-- :max-width="MaxWidth" -->

    <v-img :src="video.img" class="img" :height="video.height"></v-img>
    <v-row no-gutters>
      <v-col cols="1" v-if="video.emphasis">
        <v-list-item class="pl-0 pt-3">
          <v-list-item-avatar color="white">
            <span style="font-size: 40px; color:#ffea00; font-weight: bold" class="mdi mdi-alert-decagram"></span>
          </v-list-item-avatar>
        </v-list-item>
      </v-col>
      <v-col>
        <v-card-title class="pl-1 pt-1 subtitle-1 font-weight-bold" style="word-break: break-word; padding-top: 25px !important; padding-left: 0 !important;">
          {{ title }}
        </v-card-title>
      </v-col>
    </v-row>
  </v-card>
</template>

<script>
import { PostLogGRV } from "../services/ApiRequest";

export default {
  emits: ["ShowVideoPlayer"],
  props: {
    video: {
      type: Object,
      required: true,
    },
  },
  computed: {
    alertNewVideo() {
      let DataHoraAtual = new Date();
      let DataHoraPublicacao = new Date(this.video.publishedAt);
      let TempoEmSegundos = (DataHoraPublicacao - DataHoraAtual) / 1000;

      if (Math.round(TempoEmSegundos / (60 * 60 * 24)) > -14) {
        return true;
      } else {
        return false;
      }
    },
    title() {
      return this.video.title.split('&quot;').join('"');
    },
  },
  data() {
    return {
      UrlVideoPlay: "/videoPlay/",
      MaxWidth: 0,
    };
  },
  created() {

    if (this.video.emphasis == true) {
      this.MaxWidth = 350;
    }
    else {
      this.MaxWidth = 280;
    }

    sessionStorage.setItem("onform", "videocard");
  },
  methods: {
    ShowVideoPlayer() {
      this.$emit("ShowVideoPlayer", this.video.id.videoId);
    },
    OnVideoPlayer() {
      PostLogGRV(
        "1.0.0.0",
        "CPS.EXE",
        "05107093000171",
        "VIDEO_YOUTUBE",
        this.video.title
      ).catch((erro) => {
        console.log(erro);
      });

      sessionStorage.setItem("title", this.video.title);
      sessionStorage.setItem("description", this.video.description);
      const inputElement = document.getElementById("filter");
      const valorInput = inputElement.value;
      this.$router.push({
        path: this.UrlVideoPlay + this.video.id,
        query: {
          filter:
            this.$route.query.filter != undefined
              ? this.$route.query.filter
              : valorInput,
        },
      });
    },
  },
};
</script>

<style scoped>
</style>
