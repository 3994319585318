<template>
    <div>
        <v-snackbar timeout="2000" rounded variant="outlined" v-model="alert.ativo" :color="alert.tipo">
            <div class="text-subtitle-1 pb-2" style="font-weight: bold">{{ alert.titulo }}</div>
            <p>{{ alert.texto }}</p>
        </v-snackbar>
    </div>
</template>

<script>
export default {
    name: 'Alert',
    props: {
        alert: Object
    },
}
</script>

<style scoped>
</style>
