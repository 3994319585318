import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import VideoPlay from '../components/VideoPlay.vue'
import HomeAdmin from '../components/Admin/HomeAdmin.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/:filter?',
    name: 'Home',
    component: Home
  },
  {
    path: '/videoPlay/:id?',
    name: 'VideoPlay',
    component: VideoPlay
  },
  {
    path: '/admin/home',
    name: 'HomeAdmin',
    component: HomeAdmin
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
