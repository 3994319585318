<template>
  <div>
    <Navbar @SendFilter="ReceiveFilter" />
    <v-container fluid>
      <iframe
        v-if="ShowVideo"
        style="width: 100%; height: 400px"
        :src="this.UrlYoutube + this.idVideo + '?autoplay=1'"
        frameborder="0"
        allow="autoplay"
        allowfullscreen
      ></iframe>

      <div v-if="loading || ResourceVideosFilter.length > 0">
        <!-- Animação que mostra os cards carregando -->
        <v-row v-if="loading">
          <v-col
            :xs="12"
            :sm="3"
            v-for="resource in loading ? 12 : ResourceVideosFilter"
            :key="resource.id"
          >
            <v-skeleton-loader type="card-avatar" :loading="loading">
              <video-card
                :video="resource"
                @ShowVideoPlayer="ShowVideoPlayer"
              ></video-card>
            </v-skeleton-loader>
          </v-col>
        </v-row>
        <div v-else>
          <v-row class="v-row-cards">
            <v-col
              class="divDestaques"
              xs="12"
              :sm="6"
              v-for="resource in loading ? 12 : ResourceVideosDestaqueFilter"
              :key="resource.id"
            >
              <v-skeleton-loader
                type="card-avatar"
                :loading="loading"
                height="200"
              >
                <video-card
                  :video="resource"
                  @ShowVideoPlayer="ShowVideoPlayer"
                ></video-card>
              </v-skeleton-loader>
            </v-col>

            <v-col class="div4cards" :xs="12" :sm="width4x4DivPai">
              <v-row>
                <v-col
                  class="div4cards"
                  :sm="width4x4Div"
                  v-for="resource in loading
                    ? 12
                    : QuatroPrimeirosCardsSemDestaque"
                  :key="resource.id"
                >
                  <v-skeleton-loader type="card-avatar" :loading="loading">
                    <video-card
                      :video="resource"
                      @ShowVideoPlayer="ShowVideoPlayer"
                    ></video-card>
                  </v-skeleton-loader>
                </v-col>
              </v-row>
            </v-col>

            <v-col
              :xs="12"
              :sm="resource.width"
              v-for="resource in loading ? 12 : RestanteCardsSemDestaque"
              :key="resource.id"
            >
              <v-skeleton-loader type="card-avatar" :loading="loading">
                <video-card
                  :video="resource"
                  @ShowVideoPlayer="ShowVideoPlayer"
                ></video-card>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
      </div>
      <div v-else>
        <div
          class="sem-conteudo"
          style="
            display: flex;
            align-items: center;
            justify-content: center;
            height: 100%;
          "
        >
          <h1 style="font-size: 20px; text-align: center; color: #00acc1">
            Ops! Parece que não encontramos o conteúdo que você está procurando.
          </h1>
        </div>
      </div>
    </v-container>
    <Alert :alert="alert" />
  </div>
</template>

<script>
import Navbar from "../components/Navbar";
import videoCard from "@/components/VideoCard";
import Alert from "../components/Alert.vue";
import { RequestGETCPSVideos } from "../services/ApiRequest";

export default {
  name: "Home",
  data: () => ({
    loading: true,
    resourceVideos: [],
    resourceCompleto: [],
    ShowVideo: false,
    idVideo: "",
    filter: "",
    tamanho_vcol: 3,
    row: 1,
    UrlYoutube: "https://youtube.com/embed/",
    alert: {
      tipo: "",
      titulo: "",
      texto: "",
      ativo: false,
    },

    // importante para o layout 2x2 dos vídeos em destaque
    width4x4Div: 6,
    width4x4DivPai: 6,
    ResourceVideosDestaques: [],
    ResourceVideosSemDestaque: [],
    ResourceCompletoSemDestaque: [],
    AjustaDiv: "",
  }),
  components: {
    Navbar,
    videoCard,
    Alert,
  },
  mounted() {},
  methods: {
    OnAfterGETCPSVideos(res) {
      this.resourceCompleto = JSON.parse(res.data.itemsAdmin);
    
      for (let i = 0; i < this.resourceCompleto.length; i++) {
        if ("visible" in this.resourceCompleto[i]) {
          if (this.resourceCompleto[i].visible == true) {
            this.resourceVideos.push(this.resourceCompleto[i]);
          }
        } else {
          this.resourceCompleto[i].visible = true;
          this.resourceVideos.push(this.resourceCompleto[i]);
        }

        if (this.resourceCompleto[i].visible) {
          // Aumenta o tamanho dos vídeos em destaque
          if (this.resourceCompleto[i].emphasis) {
            this.resourceCompleto[i].width = 6;
            this.resourceCompleto[i].height = "550px";
            this.ResourceVideosDestaques.push(this.resourceCompleto[i]);
          } else {
            this.resourceCompleto[i].width = 3;
            this.resourceCompleto[i].height = "215px";
            this.ResourceVideosSemDestaque.push(this.resourceCompleto[i]);
          }
        }
      }

      this.ResourceCompletoSemDestaque = this.ResourceVideosSemDestaque;
      this.loading = false;

      if (this.$route.query.filter != undefined) {
        this.filter = this.$route.query.filter;
      }
    },

    ShowVideoPlayer(pId) {
      this.ShowVideo = false;
      this.idVideo = pId;
      this.ShowVideo = true;
    },

    ReceiveFilter(pFilter) {
      this.ResourceVideosSemDestaque = this.ResourceCompletoSemDestaque;
      this.filter = pFilter;
    },

    onError(erro) {
      this.alert.tipo = "error";
      this.alert.texto = erro.message;
      this.alert.titulo = "Erro!";
      this.alert.ativo = true;
    },

    resetaArrayQuatroPrimeiros() {
      let resource = this.ResourceVideosSemDestaque.filter((item) =>
        item.title.toLowerCase().includes(this.filter.toLowerCase())
      ).sort(
        (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
      );

      return resource.slice(0, 4);
    },

    resetaArrayRestante() {
      let resource = this.ResourceVideosSemDestaque.filter((item) =>
        item.title.toLowerCase().includes(this.filter.toLowerCase())
      ).sort(
        (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
      );

      return resource.slice(4);
    },
  },

  created() {
    sessionStorage.setItem("onform", "home");
    RequestGETCPSVideos()
      .then(this.OnAfterGETCPSVideos)
      .catch((erro) => {
        console.log(erro);
      });
  },

  computed: {
    ResourceVideosFilter() {
      return this.resourceVideos
        .filter((item) =>
          item.title.toLowerCase().includes(this.filter.toLowerCase())
        )

        .sort((a, b) => {
          if (a.emphasis && !b.emphasis) {
            return -1;
          } else if (!a.emphasis && b.emphasis) {
            return 1;
          } else {
            return a.sequence - b.sequence;
          }
        })
        .sort(
          (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
        );
    },

    ResourceVideosDestaqueFilter() {
      return this.ResourceVideosDestaques.filter((item) =>
        item.title.toLowerCase().includes(this.filter.toLowerCase())
      )
        .sort((a, b) => {
          if (a.emphasis && !b.emphasis) {
            return -1;
          } else if (!a.emphasis && b.emphasis) {
            return 1;
          } else {
            return a.sequence - b.sequence;
          }
        })
        .sort(
          (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
        );
    },

    QuatroPrimeirosCardsSemDestaque() {
      return this.resetaArrayQuatroPrimeiros();
    },

    RestanteCardsSemDestaque() {
      return this.resetaArrayRestante();
    },
  },

  watch: {
    AjustaDiv(novoValor) {
      if (novoValor > 0) {
        if (novoValor % 2 == 0) {
          this.width4x4DivPai = 12;
          this.width4x4Div = 3;
        } else {
          this.width4x4DivPai = 6;
          this.width4x4Div = 6;
        }
      } else {
        this.width4x4DivPai = 12;
        this.width4x4Div = 3;
      }
    },
  },

  updated() {
    this.AjustaDiv = this.ResourceVideosDestaqueFilter.length;
  },
};
</script>
<style lang="scss"></style>