<template>
  <div>
    <div v-if="!isLogged">
      <Login @onLoginSuccess="onLoginSuccess" @onLogginError="onLogginError" />
    </div>
    <div v-else>
      <NavbarAdmin
        @SendFilter="ReceiveFilter"
        @onAlertSucess="onAlertSucessAddingVideo"
        @OnLoadVideos="OnLoadVideos"
      />
      <v-container fluid>
        <iframe
          v-if="ShowVideo"
          style="width: 100%; height: 400px"
          :src="this.UrlYoutube + this.idVideo + '?autoplay=1'"
          frameborder="0"
          allow="autoplay"
          allowfullscreen
        ></iframe>
        <div v-if="loading || ResourceVideosFilter.length > 0">
          <v-row>
            <v-col
              cols="12"
              sm="6"
              md="4"
              lg="3"
              v-for="resource in loading ? 12 : ResourceVideosFilter"
              :key="resource.id"
              class="mx-xs-auto"
            >
              <v-skeleton-loader type="card-avatar" :loading="loading">
                <video-card
                  :video="resource"
                  :destaque="resource.emphasis"
                  @ShowVideoPlayer="ShowVideoPlayer"
                  @OnLoadVideos="OnLoadVideos"
                  @onAlertSucess="onAlertSucessEditVideo"
                  @onAlertSucessDestaque="onAlertSucessDestaque"
                ></video-card>
              </v-skeleton-loader>
            </v-col>
          </v-row>
        </div>
        <div v-else>
          <div
            class="sem-conteudo"
            style="
              display: flex;
              align-items: center;
              justify-content: center;
              height: 100%;
            "
          >
            <h1 style="font-size: 20px; text-align: center; color: #00acc1">
              Ops! Parece que não encontramos o conteúdo que você está
              procurando.
            </h1>
          </div>
        </div>
      </v-container>
    </div>
    <Alert :alert="alert" />
  </div>
</template>

<script>
import NavbarAdmin from "./NavbarAdmin.vue";
import videoCard from "../Admin/VideoCardAdmin.vue";
import { RequestGETCPSVideos } from "../../services/ApiRequest";
import Login from "./LoginAdmin.vue";

import Alert from "../Alert.vue";

export default {
  name: "Home",
  computed: {
    ResourceVideosFilter() {
      return this.resourceVideos
        .filter((item) =>
          item.title.toLowerCase().includes(this.filter.toLowerCase())
        )
        .sort((a, b) => {
          if (a.emphasis && !b.emphasis) {
            return -1;
          } else if (!a.emphasis && b.emphasis) {
            return 1;
          } else {
            return a.sequence - b.sequence;
          }
        })
        .sort(
          (a, b) => new Date(b.publication_date) - new Date(a.publication_date)
        );
    },
  },
  data: () => ({
    loading: true,
    resourceVideos: [],
    ShowVideo: false,
    idVideo: "",
    filter: "",
    UrlYoutube: "https://youtube.com/embed/",
    alert: {
      tipo: "",
      titulo: "",
      texto: "",
      ativo: false,
    },
    isLogged: false,
  }),
  components: {
    NavbarAdmin,
    videoCard,
    Alert,
    Login,
  },
  mounted() {},
  methods: {
    OnAfterGETCPSVideos(res) {
      this.resourceVideos = JSON.parse(res.data.itemsAdmin);

      console.log(this.resourceVideos);

      this.loading = false;

      if (this.$route.query.filter != undefined) {
        this.filter = this.$route.query.filter;
      }
    },
    ShowVideoPlayer(pId) {
      this.ShowVideo = false;
      this.idVideo = pId;
      this.ShowVideo = true;
    },
    ReceiveFilter(pFilter) {
      this.filter = pFilter;
    },
    OnLoadVideos() {
      RequestGETCPSVideos().then(this.OnAfterGETCPSVideos).catch(this.onError);
    },
    onAlertSucessAddingVideo() {
      this.alert.tipo = "success";
      this.alert.texto = "Vídeo adicionado com sucesso!";
      this.alert.titulo = "Sucesso!";
      this.alert.ativo = true;
    },
    onAlertSucessEditVideo() {
      this.alert.tipo = "success";
      this.alert.texto = "Vídeo editado com sucesso!";
      this.alert.titulo = "Sucesso!";
      this.alert.ativo = true;
    },
    onAlertSucessDestaque() {
      this.alert.tipo = "success";
      this.alert.texto = "Vídeo destacado com sucesso!";
      this.alert.titulo = "Sucesso!";
      this.alert.ativo = true;
    },
    onLoginSuccess() {
      this.isLogged = true;
    },
    onLogginError() {
      this.alert.tipo = "error";
      this.alert.texto = "Senha inválida!";
      this.alert.titulo = "Erro!";
      this.alert.ativo = true;
    },
    onError(erro) {
      this.alert.tipo = "error";
      this.alert.texto = erro.message;
      this.alert.titulo = "Erro!";
      this.alert.ativo = true;
    },
  },
  created() {
    sessionStorage.setItem("onform", "home");
    this.OnLoadVideos();
  },
};
</script>