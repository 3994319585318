<template>
  <nav id="navbar">
    <v-app-bar flat app clipped-left>
      <v-toolbar-title class="mt-3">
        <v-btn v-if="larguraApp >= 600" text style="color: #00acc1" plain class="mb-3 font-weight-bold" @click="OnHome">
          <span style="font-size:25px;margin-right: 10px;" class="mdi mdi-home"></span> Home
        </v-btn>

        <span v-else @click="OnHome" style="color: #00acc1;font-size:30px;margin-right: 10px;"
          class="mdi mdi-home"></span>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-text-field id="filter" flat hide-details append-icon="mdi-magnify" placeholder="Pesquisa" outlined dense
        style="margin-left: 40px;margin-right: 20px;" v-model="searchText" @click:append="search"
        @keyup.enter="search"></v-text-field>
      <v-spacer></v-spacer>
      <img style="width: 45px" src="../assets/imgs/logo.png" />
    </v-app-bar>
  </nav>
</template>

<script>
export default {
  emits: ["SendFilter"],
  data: () => ({
    drawer: false,
    larguraApp: window.innerWidth,
    items: [
      {
        header: null,
        pages: [{ title: "Home", link: "/", icon: "mdi-home" }],
      },
    ],
    searchText: "",
  }),
  components: {},
  created() {
    this.searchText = this.$route.query.filter;
  },
  methods: {
    OnHome() {
      try {
        this.$router.push({ path: "/" });
        this.searchText = "";
        this.$emit("SendFilter", "");
      } catch (error) {
        console.log(error)
      }

    },
    search() {
      if (!this.$route.path.includes("/videoPlay")) {
        this.$emit("SendFilter", this.searchText);
        this.$router.push({ path: "/", query: { filter: this.searchText } });
      } else {
        this.$router.push({ path: "/", query: { filter: this.searchText } });
      }
    },
  },
  updated() { },
  mounted() {
    this.drawer = this.$vuetify.breakpoint.mdAndDown ? false : true;
    this.drawer = this.$route.name === "Watch" ? false : this.drawer;
  },
};
</script>

<style lang="scss">
#navbar {
  .active-item {
    .v-list-item__icon {
      color: #00acc1 !important;
    }
  }

  .v-navigation-drawer__border {
    width: 0 !important;
  }

  .vuebar-element {
    height: 250px;
    width: 100%;
    max-width: 500px;
  }

  .vb>.vb-dragger {
    z-index: 5;
    width: 10px;
    right: 0;
  }

  .vb>.vb-dragger>.vb-dragger-styler {
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
    -webkit-transform: rotate3d(0, 0, 0, 0);
    transform: rotate3d(0, 0, 0, 0);
    -webkit-transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;
    transition: background-color 100ms ease-out, margin 100ms ease-out,
      height 100ms ease-out;

    margin: 5px 5px 5px 0;
    border-radius: 20px;
    height: calc(100% - 10px);
    display: block;
  }

  .v-navigation-drawer__content:hover .vb>.vb-dragger>.vb-dragger-styler {
    width: 10px;
  }

  .vb.vb-scrolling-phantom>.vb-dragger>.vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.3);
    background-color: rgba(255, 255, 255, 0.3);
  }

  .vb>.vb-dragger:hover>.vb-dragger-styler {
    margin: 0px;
    width: 10px;
  }

  .vb.vb-dragging>.vb-dragger>.vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
    margin: 0px;
    height: 100%;
  }

  .vb.vb-dragging-phantom>.vb-dragger>.vb-dragger-styler {
    background-color: rgba(48, 121, 244, 0.5);
  }
}
</style>
