<template>
  <v-card class="content-bg card mx-auto pa-1 transparent" flat max-width="350px">
    <v-img :src="video.img" height="200px" @click="OnVideoPlayer" class="img"></v-img>
    <v-row no-gutters style="display: block">
      <div style="display: flex; margin-bottom: 0; padding-bottom: 0">
        <v-col cols="2" v-if="isDestaque" style="margin-bottom: 0; padding-bottom: 0">
          <v-list-item class="pl-0 pt-3">
            <v-list-item-avatar color="white">
              <span style="font-size: 40px; color: #ffea00; font-weight: bold" class="mdi mdi-alert-decagram"></span>
            </v-list-item-avatar>
          </v-list-item>
        </v-col>
        <v-col style="margin-bottom: 0; padding-bottom: 0">
          <v-card-title class="pl-1 pt-1 subtitle-1 font-weight-bold" style="word-break: break-word">
            {{ title }}
          </v-card-title>
        </v-col>
      </div>
      <div class="checkboxes">
        <v-checkbox density="compact" label="Vídeo destaque" v-model="isDestaque" style="font-weight: bold;"
          @click="onClickDestaque"></v-checkbox>
        <v-checkbox density="compact" label="Visível" style="font-weight: bold;" v-model="isVisible"
          @click="onClickVisible"></v-checkbox>
      </div>
    </v-row>
    <v-dialog persistent v-model="dialog" style="background: none">
      <v-col cols="12" sm="12" md="6 offset-md-3" lg="4 offset-lg-4" style="padding: 0">
        <v-card>
          <v-card-title style="font-size: 16px">Sequencia:</v-card-title>
          <v-text-field type="number" style="width: 50%; margin-left: 25%" v-model="seqDestaque"></v-text-field>
          <v-divider></v-divider>
          <v-card-title style="font-size: 16px">Até quando o vídeo ficará em destaque?</v-card-title>
          <v-text-field type="date" style="width: 50%; margin-left: 25%" v-model="destaqueDate"></v-text-field>
          <v-card-actions style="display: flex; justify-content: end; align-items: end">
            <v-btn @click="onCancel" color="error">Cancelar</v-btn>
            <v-btn color="success" @click="onSaveClick">Salvar</v-btn>
          </v-card-actions>
        </v-card>
      </v-col>
    </v-dialog>
    <v-btn v-if="isManual" style="background-color: #1E88E5;" class="btn-edit-video" density="compact" :icon="true"
      height="42px" width="42px" @click="onEditClick"><v-icon class="mdi mdi-file-edit"
        style="color: white"></v-icon></v-btn>
    <v-btn v-else style="background-color: red;" class="btn-edit-video" density="compact" :icon="true" height="42px"
      width="42px"><v-icon class="mdi mdi-youtube" style="color: white; width: 100%;"></v-icon></v-btn>
    <v-dialog v-model="editingVideo">
      <v-col cols="12" sm="12" md="6 offset-md-3" lg="4 offset-lg-4">
        <v-card>
          <v-card-title>
            <span class="text-h5">Editar Vídeo</span>
          </v-card-title>
          <v-card-text>
            <v-container>
              <v-row class="form-edit-video">
                <v-col cols="12">
                  <v-text-field :rules="[onEmptyField]" id="titulo" density="compact" v-model="videoTitulo"
                    label="Título"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field :rules="[verifyUrlRules]" id="url" density="compact" v-model="videoURL"
                    label="URL"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-text-field id="url-img" density="compact" v-model="videoURLImg" label="URL da imagem"></v-text-field>
                </v-col>
                <v-col cols="12">
                  <v-textarea id="descricao" rows="2" v-model="videoDescricao" density="compact"
                    label="Descrição"></v-textarea>
                </v-col>
              </v-row>
              <v-card-actions style="display: flex; justify-content: end; align-items: end">
                <v-spacer></v-spacer>
                <v-btn @click="onCancelEdit" color="error">Cancelar</v-btn>
                <v-btn color="success" @click="saveEdit">Salvar</v-btn>
              </v-card-actions>
            </v-container>
          </v-card-text>
        </v-card>
      </v-col>
    </v-dialog>
  </v-card>
</template>

<script>
import {
  PostLogGRV,
  RequestPostDestaque,
  RequestPostVisibleVideo,
  RequestPutVideoAdicionado,
} from "../../services/ApiRequest";

export default {
  name: "VideoCardAdmin",
  emits: ["ShowVideoPlayer", "OnLoadVideos", "onAlertSucess", "onAlertSucessDestaque"],
  props: {
    video: {
      type: Object,
      required: true,
    },
    destaque: Boolean
  },
  computed: {
    alertNewVideo() {
      let DataHoraAtual = new Date();
      let DataHoraPublicacao = new Date(this.video.hora_publicacao);
      let TempoEmSegundos = (DataHoraPublicacao - DataHoraAtual) / 1000;

      if (Math.round(TempoEmSegundos / (60 * 60 * 24)) > -14) {
        return true;
      } else {
        return false;
      }
    },
    title() {
      return this.video.title.replace("&quot;", '"').replace("&quot;", '"');
    },
  },
  data() {
    return {
      UrlVideoPlay: "/videoPlay/",
      isDestaque: this.destaque,
      isVisible: true,
      destaqueDate: "",
      dialog: false,
      videoID: "",
      seqDestaque: 0,
      isManual: false,
      editingVideo: false,
      videoTitulo: "",
      videoURL: "",
      videoDescricao: "",
      videoURLImg: "",
    };
  },
  created() {
    sessionStorage.setItem("onform", "videocard");

    if (this.video.visible != undefined) {
      this.isVisible = this.video.visible;
    }

    if (this.video.type === "manual") {
      this.isManual = true;
    }
  },
  methods: {
    ShowVideoPlayer() {
      this.$emit("ShowVideoPlayer", this.video.id);
    },

    OnVideoPlayer() {
      PostLogGRV(
        "1.0.0.0",
        "CPS.EXE",
        "05107093000171",
        "VIDEO_YOUTUBE",
        this.video.title
      ).catch((erro) => {
        console.log(erro);
      });

      sessionStorage.setItem("description", this.video.snippet.description);
      const inputElement = document.getElementById("filter");
      const valorInput = inputElement.value;
      this.$router.push({
        path: this.UrlVideoPlay + this.video.id,
        query: {
          filter:
            this.$route.query.filter != undefined
              ? this.$route.query.filter
              : valorInput,
        },
      });
    },
    onClickDestaque() {
      this.videoID = this.video.id;
      if (this.isDestaque == true) {
        this.dialog = true;
      } else {
        this.destaqueDate = new Date()
        this.OnPostDestaque();
      }
      this.formatDestaqueDate();
    },
    onClickVisible() {
      this.videoID = this.video.id;

      const date = new Date().toLocaleString();

      const body = {
        id: this.videoID,
        visible: this.isVisible,
        data_acao: date,
      };

      RequestPostVisibleVideo(body)
        .then()
        .catch((erro) => {
          console.log(erro);
        });
    },
    onCancel() {
      this.isDestaque = false;
      this.isVisible = false;
      this.dialog = false;
    },
    formatDestaqueDate() {
      let date = new Date();

      const day = date
        .getDate()
        .toString()
        .padStart(2, "0");
      const month = (date.getMonth() + 1).toString().padStart(2, "0");
      const year = date.getFullYear().toString();

      this.destaqueDate = `${year}-${month}-${day}`;
    },
    afterPostDestaqueVideo(res) {
      console.log(res.data);
    },
    OnSaveDestaque() {
      this.dialog = false;
      this.$emit('OnLoadVideos', true);

      if (this.isDestaque) {
        this.$emit("onAlertSucessDestaque");
      } 
    },
    OnPostDestaque() {
      const JSONSend = {
        id: this.videoID,
        destaque_date: this.destaqueDate,
        destaque: this.isDestaque,
        sequencia: this.seqDestaque,
      };

      RequestPostDestaque(JSONSend)
        .then(this.OnSaveDestaque)
        .catch((error) => {
          console.error(error);
        });
    },
    onSaveClick() {
      this.OnPostDestaque();
    },
    onEditClick() {
      this.videoID = this.video.id;
      this.videoTitulo = this.video.title;
      this.videoDescricao = this.video.description;
      this.videoURL = this.video.url;
      alert(this.videoURL)
      this.videoURLImg = this.video.img;
      this.editingVideo = true;
    },
    onCancelEdit() {
      this.editingVideo = false;
      this.clearFields();
    },
    clearFields() {
      this.videoID = "";
      this.videoTitulo = "";
      this.videoDescricao = "";
      this.videoURL = "";
    },
    saveEdit() {
      const editedVideo = {
        id: this.videoID,
        type: this.video.type,
        title: this.videoTitulo,
        url: this.videoURL,
        urlImg: this.videoURLImg,
        description: this.videoDescricao
      }

      RequestPutVideoAdicionado(editedVideo).then(this.onEditSaved);
    },
    onEmptyField(value) {
      if (!value) {
        return "Este campo é obrigatório.";
      }
      return true; // Retorna true se o valor for válido.
    },
    verifyUrlRules() {
      if (!this.videoURL.includes("https://www.youtube.com/watch?v=")) {
        return "O campo URL deve ser preenchido no formato: https://www.youtube.com/watch?v=XXXXXXXXXX";
      }
      return true;
    },
    onEditSaved() {
      this.editingVideo = false;
      this.clearFields();
      this.$emit("onAlertSucess");
      this.$emit('OnLoadVideos', true);
    },
  },
};
</script>

<style scoped>
.checkboxes {
  display: flex;
  justify-content: space-around;
}

.img:hover {
  cursor: pointer;
}

.btn-edit-video {
  top: -3%;
  left: -2%;
  position: absolute;
}
</style>
